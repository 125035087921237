import React from 'react';
import Modal from './Modal';
import ParticleBackground from './ParticleBackground';
import { SocialIcon } from 'react-social-icons';
import LoadingAnimation from './LoadingAnimation';

/** ToDo
 * 
 */

class Main extends React.Component{
    constructor(){
        super();
        this.state={
            isModalOpen: false,
            isLoaded: false,
            displayContent: false,
        }
        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidMount(){
        setTimeout(() =>{
            this.setState({isLoaded: true},
                () => {
                    setTimeout(() =>{
                        this.setState({displayContent:true});
                    }, 800);
                });
        }, 1000)
    }
    toggleModal = () =>{
        this.setState({ isModalOpen: !this.state.isModalOpen });
    }

    render(){
        const componentClasses = ['content-container'];

        if(this.state.displayContent === true){
            componentClasses.push('fade-in');
        }
        return(
            <> 
                {!this.state.isLoaded &&
                    <div className="animation-container">
                        <LoadingAnimation />
                    </div>
                }
                {this.state.isLoaded &&
                <>
                <div className={componentClasses.join(' ')}>
                    <div className="header-container">
                        <div className="header">
                            <h1> Lilian Galezewska</h1>
                            <div className="social-icons">
                                <SocialIcon url="https://www.linkedin.com/in/lilian-galezewska-3614178"bgColor="#E0DADA" target="_blank" rel="noreferrer" />
                                <SocialIcon url="https://twitter.com/LilianMGR" bgColor="#E0DADA" target="_blank" rel="noreferrer"/>
                                <SocialIcon url="https://www.instagram.com/lilimay/" bgColor="#E0DADA" target="_blank" rel="noreferrer" />
                            </div>
                        </div>
                    </div>
                    <div className="content-body">    
                        <div className="content-block">
                            <strong>Hi!</strong>
                            I’m Lilian.<br />
                            A web developer (and more) with 10+ years experience 
                            designing and building elegant solutions.<br />
                            <strong>I solve problems.</strong>
                            I'm an Australian, based in Poznań, Poland.<br />
                            
                            <div className="cta"><button className="cta-button" onClick={this.toggleModal}>Want to know more?</button>  or just <a href="mailto:lilian.galezewska@gmail.com?subject=Hello from your website">email me!</a></div>
                        </div>
                    </div>
                    <div className="footer">
                    <a href="mailto:lilian.galezewska@gmail.com?subject=Hello from your website">lilian.galezewska@gmail.com</a>
                    </div>
                </div>
                <ParticleBackground />
                <Modal showModal={this.state.isModalOpen} onClose={this.toggleModal} modalTitle="Want to know more?">
                    <ul className="list">
                        <li><strong> I use:</strong> JavaScript (Angular, React), HTML, CSS. And all that goes along with building modern web apps. Webpack, npm. Jest, Enzyme. ESLint, Prettier. And so, so much more.</li>
                        <li><strong> I am:</strong> Available for work now! I have permission to work in Poland, so my future employer doesn't have to worry about a work permit.</li>
                        <li><strong> I have:</strong> 
                            <ul>
                                <li>10+ years experience designing and building all kinds of websites.</li>
                                <li>6 years experience working on, and leading, remote teams.</li>
                                <li>Proven experience improving development workflows and implementing best practices on existing and new codebases.</li>
                                <li>Also held positions doing UX research and design, IT and Team Management.</li>
                            </ul>
                        </li>
                        <li><strong> But why</strong> is this website so... basic? Despite being a perfectionist, I had to just <strong>ship it</strong>. The next version might even include some projects!</li>
                    </ul>
                    <span>CV, references and code samples available on request<br />
                    <a href="mailto:lilian.galezewska@gmail.com?subject=Hello from your website">lilian.galezewska@gmail.com</a></span>
                </Modal>
                </>
                }
            </>
        )
    }
}

export default Main;